@import "../../../../assets/scss/abstracts/vars";
@import "../../../../assets/scss/abstracts/mixins";

// Перечень опций доступных к подключению
.available-services {

  // Отдельная опция из перечня
  &__service {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 9px 8px;
    gap: 16px;
  }

  // Иконка для опции
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: $color-light-sandToGrey140;
    border-radius: 12px;
    width: 48px;
    height: 48px;
  }

  // Название опции
  &__text-title {
    @include text-lg;
  }

  // Краткое описание опции
  &__text-desc {
    color: $color-light-grey60ToGrey100;
    @include text-md;
  }
}
