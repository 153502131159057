@import "../../assets/scss/abstracts/vars";
@import "../../assets/scss/abstracts/mixins";

// Контейнер для отображения состояний посетителя портала
.auth-portal-container {
  width: 500px;
  max-width: 100%;
  margin: 0 auto 40px;
  padding-bottom: 90px;
}

// Содержимое контейнера
.auth-portal-content {
  @include vgap24;

  // Заголовок контейнера
  &__title {
    @include vgap16;
    @include title-sm;
  }
}

.auth-portal-content,
// Контейнер для заглушки пока выполняется запрос к серверу
.placeholder-container {
  display: flex;
  flex-flow: column;
  background-color: $color-white;
  @include sh-xl;
  border-radius: 16px;
  padding: 32px;

  @media (max-width: $qsmall) {
    padding: 15px;
  }
}

.utility-service {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &__text {
    font-family: $medium;
  }
}