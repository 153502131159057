@import "../../../assets/scss/abstracts/vars";

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $color-light-green100ToGreen60;
  color: $color-white;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: $color-light-orange100ToOrange60;
  color: $color-white;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $color-light-red120ToRed30;
  color: $color-white;
}
