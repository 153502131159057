@import "src/assets/scss/abstracts/vars";
@import "src/assets/scss/abstracts/mixins";

.button {
  font-family: $medium;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  position: relative;
  transition: all 0.2s;

  // Для некликабельной кнопки
  &[disabled] {
    outline: none;
    cursor: not-allowed;
    color: $color-light-grey60ToGrey100 !important;
    background-color: $color-light-grey05ToGrey140 !important;

    &:not(.button--icon) {
      background-color: $color-light-grey05ToGrey140;
    }
  }
}

@import "types";
@import "btn-support";
@import "btn-refresh";
@import "btns-group";
@import "spinner";
