@import "src/assets/scss/abstracts/mixins";

.checkbox {
  // Модификаторы для установки размера
  &--lg {
    @include text-lg;

    input {
      height: 24px;
      width: 24px;

      + span {
        height: 24px;
        width: 24px;
      }
    }
  }

  &--md {
    @include text-md;

    input {
      height: 20px;
      width: 20px;

      + span {
        height: 20px;
        width: 20px;
      }
    }
  }

  &--sm {
    @include text-sm;

    input {
      height: 16px;
      width: 16px;

      + span {
        height: 16px;
        width: 16px;
      }
    }
  }

  // Модификатор для состояние чекбокса, который недоступен для выбора
  &--disabled {
    input + span:before {
      outline-color: $color-light-grey05ToGrey140;
      background-color: $color-light-grey05ToGrey140;
    }
  }

  // Модификатор для состояния ошибки чекбокса
  &--invalid {
    input + span:before {
      outline-color: $color-light-red100ToRed60 !important;
      background-color: $color-light-red10ToRed140 !important;
    }
  }
}
