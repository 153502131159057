@import "src/assets/scss/abstracts/vars";
@import "src/assets/scss/abstracts/mixins";

.select {
  // Модификаторы для установки размера
  &--xxl {
    .select__control {
      min-height: 52px;
      @include text-lg;
    }
  }

  &--xl {
    .select__control {
      min-height: 48px;
      @include text-lg;
    }
  }

  &--lg {
    .select__control {
      min-height: 40px;
      @include text-md;
    }
  }

  &--md {
    .select__control {
      min-height: 36px;
      @include text-md;
    }
  }

  &--sm {
    .select__control {
      min-height: 32px;
      @include text-md;
    }
  }

  &--xs {
    .select__control {
      min-height: 24px;
      border-radius: 4px;
      @include text-sm;
    }
  }

  // Модификатор на всю ширину родителя
  &--stretch {
    width: 100%;
  }

  // Модификатор при невалидном значении селекта
  &--invalid {
    .select__control {
      outline: 1px solid $color-light-red100ToRed60;

      &:hover,
      &.select__control--is-focused {
        outline: 2px solid $color-light-red100ToRed60;
      }
    }
  }
}
