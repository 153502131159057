// Цвета для основного текста и фона
$color-light-primaryToWhite: #2A2E35;
$color-dark-primaryToWhite: #FFFFFF;

$color-light-primaryToGrey30: #2A2E35;
$color-dark-primaryToGrey30: #404247;

$color-light-sandToGrey140: #F4F4F4;
$color-dark-sandToGrey140: #404247;

$color-light-whiteToPrimary: #FFFFFF;
$color-dark-whiteToPrimary: #2A2E35;


// Оттенки серого, обводки, текст, бэкграунд
$color-light-grey140ToGrey10: #404247;
$color-dark-grey140ToGrey10: #E9EAEA;

$color-light-grey120ToGrey30: #55575C;
$color-dark-grey120ToGrey30: #D5D5D6;

$color-light-grey100ToGrey60: #808185;
$color-dark-grey100ToGrey60: #AAABAD;

$color-light-grey60ToGrey100: #AAABAD;
$color-dark-grey60ToGrey100: #808185;

$color-light-grey30ToGrey120: #D5D5D6;
$color-dark-grey30ToGrey120: #55575C;

$color-light-grey10ToGrey140: #E9EAEA;
$color-dark-grey10ToGrey140: #404247;

$color-light-grey05ToGrey140: #F8F8F8;
$color-dark-grey05ToGrey140: #404247;

$color-light-grey01ToGrey140: #FBFBFB;
$color-dark-grey01ToGrey140: #404247;


// Цвета для ссылок
$color-light-blue120ToBlue30: #0F5D8A;
$color-dark-blue120ToBlue30: #199BE6;

$color-light-blue100ToBlue60: #147CB8;
$color-dark-blue100ToBlue60: #50B3EC;

$color-light-blue60ToBlue100: #199BE6;
$color-dark-blue60ToBlue100: #147CB8;

$color-light-blue30ToBlue120: #50B3EC;
$color-dark-blue30ToBlue120: #0F5D8A;


// Цвета для уведомлений об успехе и алертов
$color-light-green120ToGreen30: #008E7D;
$color-dark-green120ToGreen30: #E8F7F4;

$color-light-green100ToGreen60: #19B28D;
$color-dark-green100ToGreen60: #BFE9DF;

$color-light-green60ToGreen100: #52C5A9;
$color-dark-green60ToGreen100: #52C5A9;

$color-light-green30ToGreen120: #BFE9DF;
$color-dark-green30ToGreen120: #19B28D;

$color-light-green10ToGreen140: #E8F7F4;
$color-dark-green10ToGreen140: #008E7D;


// Цвета для уведомлений о предупреждении и алертов
$color-light-orange120ToOrange30: #CB662F;
$color-dark-orange120ToOrange30: #FDDAC7;

$color-light-orange100ToOrange60: #F88545;
$color-dark-orange100ToOrange60: #FAA373;

$color-light-orange60ToOrange100: #FAA373;
$color-dark-orange60ToOrange100: #F88545;

$color-light-orange30ToOrange120: #FDDAC7;
$color-dark-orange30ToOrange120: #CB662F;

$color-light-orange10ToOrange140: #FEF3EC;
$color-dark-orange10ToOrange140: #75523F;


// Цвета для уведомлений об ошибке и алертов
$color-light-red120ToRed30: #C74952;
$color-dark-red120ToRed30: #FDEEEE;

$color-light-red100ToRed60: #EE505A;
$color-dark-red100ToRed60: #FACED1;

$color-light-red60ToRed100: #F27C83;
$color-dark-red60ToRed100: #F27C83;

$color-light-red30ToRed120: #FACED1;
$color-dark-red30ToRed120: #EE505A;

$color-light-red10ToRed140: #FDEEEE;
$color-dark-red10ToRed140: #C74952;


// Цвета оверлея модального окна
$color-light-opacity: rgba($color-primary, .4);
$color-dark-opacity: rgba($color-onPrimary, .6);


// Цвета для теней
$color-light-shadow: rgba($color-primary, 0.08);
$color-dark-shadow: rgba($color-onPrimary, 0.24);