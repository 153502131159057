// Блок для текста с кнопкой
.button-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Блок со спинером отобраемый на месте текста
.button-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
