@import "../../../assets/scss/abstracts/vars";
@import "../../../assets/scss/abstracts/mixins";

/* Переопределение классов для компонента уведомления
  (https://fkhadra.github.io/react-toastify/how-to-style#override-existing-css-classes)
  */

.Toastify__toast-container {
  max-width: 480px;
  width: auto;
  padding: 0 15px;
}

.Toastify__toast {
  padding: 18px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.Toastify__toast-icon {
  margin-right: 18px;
  align-self: start;
  width: auto;
}

.Toastify__toast-body {
  padding: 0;
  margin-right: 10px;

  & + .button {
    align-self: flex-start;
  }
}

.Toastify__close-button {
  display: flex;
  width: 20px;
  height: 20px;

  > svg {
    height: 20px;
    width: 20px;
  }
}

// Блок с содержимым уведомления
.notify-message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include text-lg;
  gap: 8px 16px;

  // Заголовок уведомления
  &__title {
    font-family: $medium;
  }

  &__content {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }
}

@import "types";