// Контейнер для нескольких кнопок
.buttons-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  button {
    flex: 1;
  }
}
