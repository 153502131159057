@import "../abstracts/vars";
@import "../abstracts/mixins";

// Общие стили
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  outline: none;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  font: 14px $regular;
  overflow: auto;
  background-color: $color-light-sandToGrey140;
  color: $color-light-primaryToWhite;
}

// Основной контейнер приложения
.app-container {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}

// Содержимое страницы
.app-content {
  padding: 40px 15px;
  height: 100%;
  position: relative;

  /* Специальный модификатор, применяемый при получении данных с сервера.
    Делает недоступным всё содержимое страницы пока выполняется запрос на получение данных */
  &--not-available:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    z-index: 9999;
  }
}

// Для номера телефона
.text-phone-number {
  font: 28px $medium;
  color: $color-light-blue100ToBlue60;
  text-decoration: none;
}

.logo-company {
  width: 140px;
}

.img-cat {
  max-width: 118px;
}

// Название для поля ввода
.label-field {
  margin-bottom: 8px;
  color: $color-light-grey120ToGrey30;
}

.wrapper-qr-code {
  position: relative;
}

.qr-code {
  border: 1px solid $color-light-grey30ToGrey120;
  background-color: $color-light-sandToGrey140;
  border-radius: 24px;
  width: 214px;
  height: 214px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.qr-code-error {
  width: 100%;
  color: $color-light-grey100ToGrey60;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import "fonts";
@import "typo";
