@import "src/assets/scss/abstracts/mixins";

// Уведомление с текстом, используется инлайном внутри каких-либо элементов
.alert {
  display: flex;
  align-items: center;
  gap: 16px;
  @include text-lg;
  border-radius: 8px;
  padding: 16px;
  animation: fadein 0.3s linear;

  // Элемент с иконкой соответствующей типу уведомления
  &__icon {
    align-self: flex-start;
  }

  // Элемент с уведомлением (обертка для текст и иконки)
  &__message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    flex-wrap: wrap;
    gap: 16px;
  }
}

// Анимация появления/исчезновения уведомления
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import "types";
