@import "src/assets/scss/abstracts/mixins";

.button {
  // Модификаторы для установки размера
  &--xxl {
    min-height: 52px;
    padding: 13px 24px;
    @include text-lg;
  }

  &--xl {
    min-height: 48px;
    padding: 11px 24px;
    @include text-lg;
  }

  &--lg {
    min-height: 40px;
    padding: 9px 20px;
    @include text-md;
  }

  &--md {
    min-height: 36px;
    padding: 7px 20px;
    @include text-md;
  }

  &--sm {
    min-height: 32px;
    padding: 5px 17px;
    @include text-md;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &--xs {
    min-height: 24px;
    border-radius: 4px;
    padding: 3px 12px;
    @include text-sm;

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  // Модификаторы для кнопки с текстом и иконкой
  &--icon {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    min-height: auto;
  }

  &--icon,
  &--icon-left,
  &--icon-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--icon-left {
    img, svg {
      margin-right: 8px;
    }
  }

  &--icon-right {
    img, svg {
      margin-left: 8px;
    }
  }

  // Модификаторы для установки цвета фона
  &--primary {
    @include theme-aware("background-color", "color-accent");
    color: $color-white;

    &:hover {
      @include theme-aware("background-color", "color-onAccent");
    }

    &:active {
      @include theme-aware("background-color", "color-onPressed");
    }

    &:focus {
      outline: 2px solid $color-tab;
      @include theme-aware("outline-color", "color-tab");
    }
  }

  &--secondary {
    @include theme-aware("color", "color-accent");
    background-color: $color-white;

    &:hover {
      @include theme-aware("background-color", "color-onGhost");
    }

    &:active {
      @include theme-aware("background-color", "color-onPressedGhost");
    }

    &:focus {
      @include theme-aware("background-color", "color-onGhost");
      outline: 2px solid $color-tab;
      @include theme-aware("outline-color", "color-tab");
    }
  }

  /* Модификатор кнопки на всю ширину родителя */
  &--stretch {
    width: 100%;
  }

  /* Модификаторы кнопки для управления внутренним отступом */
  &--padding0 {
    padding: 0;
  }

  &--padding-lr0 {
    padding-left: 0;
    padding-right: 0;
  }

  &--padding-tb0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  // Модификатор для кнопки авторизации
  &--login {
    position: absolute;
    right: 32px;
    top: 34px;

    @media (max-width: 768px) {
      > div {
        display: none;
      }
    }
  }
}

/*
  Модификаторы для кнопок в компоненте Alert
*/

.button {
  &--notify-success,
  &--notify-warn,
  &--notify-error {
    border-width: 2px;
    border-style: solid;
    transition: border 0.2s;
  }

  &--notify-success {
    color: $color-light-green120ToGreen30;
    border-color: $color-light-green30ToGreen120;

    &:hover {
      border-color: $color-light-green120ToGreen30;
    }
  }

  &--notify-warn {
    color: $color-light-orange60ToOrange100;
    border-color: $color-light-orange30ToOrange120;

    &:hover {
      border-color: $color-light-orange60ToOrange100;
    }
  }

  &--notify-error {
    color: $color-light-red100ToRed60;
    border-color: $color-light-red30ToRed120;

    &:hover {
      border-color: $color-light-red100ToRed60;
    }
  }
}
