@import "src/assets/scss/abstracts/vars";
@import "src/assets/scss/abstracts/mixins";

.input {
  // Модификаторы для установки размера
  &--xxl {
    min-height: 52px;
    padding: 13px 12px;
    @include text-lg;
  }

  &--xl {
    min-height: 48px;
    padding: 9px 12px;
    @include text-lg;
  }

  &--lg {
    min-height: 40px;
    padding: 9px 12px;
    @include text-md;
  }

  &--md {
    min-height: 36px;
    padding: 7px 12px;
    @include text-md;
  }

  &--sm {
    min-height: 32px;
    padding: 5px 12px;
    @include text-md;
  }

  &--xs {
    min-height: 24px;
    border-radius: 4px;
    padding: 3px 8px;
    @include text-sm;
  }

  // Модификатор для поля ввода на всю ширину родителя
  &--stretch {
    width: 100%;
  }

  // Модификатор для невалидного поля ввода
  &--invalid {
    outline: 1px solid $color-light-red100ToRed60;

    &:hover,
    &:focus {
      outline: 2px solid $color-light-red100ToRed60 !important;
    }
  }
}
