.refresh-button {
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-dark-primaryToWhite;
    border: 1px solid $color-light-grey10ToGrey140;
}