@import "../../../assets/scss/abstracts/vars";
@import "../../../assets/scss/abstracts/mixins";

// Родительский контейнер для компонента модального окна
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0;
  overflow: auto;
  display: flex;
  padding: 0 15px;
  align-items: flex-start;
  justify-content: center;
  background-color: $color-light-opacity;

  &::-webkit-scrollbar {
    width: 0;
  }
}

// Контейнер с содержимым модального окна
.modal-content {
  position: relative;
  background: $color-white;
  @include sh-xl;
  border-radius: 16px;
  width: 500px;
  max-width: 100%;
  margin: 100px auto 20px;
  padding: 32px;
  opacity: 0;
  transform: scale(0.5);

  @media (max-width: $qsmall) {
    padding: 15px;
  }

  /* Специальный модификатор, применяемый при получении данных с сервера.
    Делает недоступным всё содержимое модального окна пока выполняется запрос на получение данных
  */
  &--not-available:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: $color-white;
    opacity: 0.35;
  }

  // Элемент модального окна, отвечающий за отображение верхней части модального окна
  &__header {
    display: flex;
    justify-content: space-between;
    @include vgap16;

    // Элемент-заголовок модального окна
    &-title {
      @include title-sm;
      padding-right: 25px;
    }

    // Кнопка закрытия
    > .button {
      align-self: flex-start;
    }
  }
}

// Анимация появления/исчезновения модального окна
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 300ms;

  .modal-content {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 300ms;

  .modal-content {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 300ms, transform 300ms;
  }
}
