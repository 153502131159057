@import "../../../assets/scss/abstracts/mixins";

.icon {
  // Модификаторы для установки размера
  &--xl {
    width: 24px;
    height: 24px;
  }

  &--lg {
    width: 20px;
    height: 20px;
  }

  &--md {
    width: 16px;
    height: 16px;
  }

  &--sm {
    width: 12px;
    height: 12px;
  }

  // Модификатор для изменения иконки при смене темы в ui
  &--accent {
    @include theme-aware("color", "color-accent");
  }
}
