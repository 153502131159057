$color-accent: #670acc;
$color-primary: #2b2d33;
$color-white: #ffffff;
$color-onAccent: #5d09b8;
$color-onPressed: #5d09b8;
$color-tab: #a774ff;
$color-onPrimary: #000000;
$color-grey120: #55575c;
$color-grey140: #404247;
$color-onGhost: rgba($color-accent, 0.1);
$color-onPressedGhost: rgba($color-accent, 0.25);
