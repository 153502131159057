@import "src/assets/scss/abstracts/vars";

// Кнопка поддержки
.support-float-button {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: $color-white;
  border-radius: 15px;
  padding: 10px 12px;
  @include sh-xl;
  animation: fadein 2s linear;

  img {
    margin-right: 12px;
  }

  // Класс завершения анимации, берется из библиотеки
  &.enter-done {
    right: 32px;
    bottom: 32px;
  }
}

// Анимация для кнопки поддержки
@keyframes fadein {
  0% {
    right: 32px;
    bottom: -100px;
    opacity: 0;
  }
  75% {
    right: 32px;
    bottom: 47px;
    opacity: 1;
  }
  100% {
    bottom: 32px;
  }
}
