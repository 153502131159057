@import "src/assets/scss/abstracts/mixins";

.checkbox {
  display: flex;
  position: relative;
  user-select: none;

  input {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: 0;

    + span {
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 10px;
      cursor: pointer;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        outline: 2px solid $color-light-grey30ToGrey120;
        border-radius: 3px;

        background-color: $color-light-sandToGrey140;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        transition: 0.2s;
      }
    }

    &:focus + span:before {
      @include theme-aware("outline-color", "color-accent");
    }

    &:not(:disabled):active + span:before {
      @include theme-aware("outline-color", "color-tab");
      @include theme-aware("background-color", "color-accent");
    }

    &:checked + span:before {
      @include theme-aware("outline-color", "color-accent");
      @include theme-aware("background-color", "color-accent");
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 11'%3e%3cpath fill='%23fff' d='M14.0952 1.17851L5.34518 9.92851C5.01974 10.2539 4.4921 10.2539 4.16667 9.92851L0 5.76184L1.17851 4.58333L4.75592 8.16074L12.9167 0L14.0952 1.17851Z'/%3e%3c/svg%3e");
    }
  }
}

@import "types";
