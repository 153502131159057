// Media Queries
$qsmall: "480px";
$qmedium: "768px";


// Основые шрифты
$fontFallback: Arial, sans-serif;
$bold: "Roboto-Bold", $fontFallback;
$medium: "Roboto-Medium", $fontFallback;
$regular: "Roboto-Regular", $fontFallback;
