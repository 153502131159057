@import "../themes/unet/theme";
@import "../themes/fnet/theme";
@import "../themes/common";

// Для установки размера заголовков
@mixin title-xxl {
    font: 32px $bold;
    line-height: 40px;
}

@mixin title-xl {
    font: 30px $bold;
    line-height: 38px;
}

@mixin title-lg {
    font: 28px $bold;
    line-height: 36px;
}

@mixin title-md {
    font: 26px $bold;
    line-height: 34px;
}

@mixin title-sm {
    font: 24px $bold;
    line-height: 30px;
}

// Для установки размера текста
@mixin text-xxl {
    font-size: 20px;
    line-height: 30px;
}

@mixin text-xl {
    font-size: 18px;
    line-height: 28px;
}

@mixin text-lg {
    font-size: 16px;
    line-height: 26px;
}

@mixin text-md {
    font-size: 14px;
    line-height: 22px;
}

@mixin text-sm {
    font-size: 12px;
    line-height: 18px;
}

// Для установки отступов
@mixin vgap16 {
    margin-bottom: 12px;
}

@mixin vgap24 {
    margin-bottom: 24px;
}

// Для выравнивания любого элемента относительно родителя
@mixin text-left {
    text-align: left;
}

@mixin text-right {
    text-align: right;
}

@mixin text-center {
    text-align: center;
}

// Для кастомизации скрола внутри любого контейнера
@mixin custom-scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 10px;
        background-color: $color-light-grey30ToGrey120;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-light-whiteToPrimary;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $color-light-grey10ToGrey140;
    }
}

// Для теней
@mixin sh-md {
    box-shadow: 0 2px 12px $color-light-shadow;
}

@mixin sh-lg {
    box-shadow: 0 4px 20px $color-light-shadow;
}

@mixin sh-xl {
    box-shadow: 0 8px 32px $color-light-shadow;
}

@function map-merge-all($maps...) {
    $result: ();

    @each $map in $maps {
        $result: map-merge($result, $map);
    }
    @return $result;
}

$themes: map-merge-all($themes-unet, $themes-fnet);

/**
 * @param  {key}     CSS-свойство
 * @param  {color}   Название цвета, определенное в теме (themes/some-theme/theme.scss)
 *
 * @example - @include theme-aware('background', 'color-background');
 * @return - background: #FFF;
 */
@mixin theme-aware($key, $color) {
    @each $theme-name, $theme-color in $themes {
        .theme-#{$theme-name} & {
            #{$key}: map-get(map-get($themes, $theme-name), $color);
        }
    }
}
