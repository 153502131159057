@import "src/assets/scss/abstracts/vars";
@import "src/assets/scss/abstracts/mixins";

/*
  Компонент селекта является сторонним решением.
  Переопределение дефолтных классов
 */
.select {
  // Непосредственно сам элемент селекта
  .select__control {
    font-family: $regular;
    border: none;
    background-color: $color-light-grey05ToGrey140;
    outline: 1px solid $color-light-grey30ToGrey120;
    border-radius: 8px;
    padding: 0 12px;
    transition: outline-color 0.2s;

    &:hover,
    &.select__control--is-focused {
      outline: 2px solid $color-accent;
      @include theme-aware("outline-color", "color-accent");
    }
  }

  // Элемент-обертка для выбранного значения селекта
  .select__value-container {
    padding-left: 0;
    padding-right: 0;
  }

  // Элемент-обертка на случай если необходим множественный выбор
  .select__value-container--is-multi {
    height: auto;
  }

  // Элемент с выбранным значением
  .select__single-value {
    margin: 0;
    color: $color-light-primaryToWhite;
    position: absolute;
  }

  // Элемент-плейсхолдер
  .select__placeholder {
    margin: 0;
    color: $color-light-grey60ToGrey100;
  }

  // Элемент-разделитель
  .select__indicator-separator {
    display: none;
  }

  // Элемент-иконка в виде стрелки
  .select__indicator {
    padding: 0;
    transform: rotate(-90deg);

    &:hover {
      color: initial;
    }
  }

  // Элемент с перечнем доступных значений
  .select__menu {
    z-index: 1001;
    border-width: 1px;
    border-style: solid;
    border-color: $color-light-grey30ToGrey120;
    background-color: $color-light-grey01ToGrey140;
  }

  // Элемент с перечнем доступных значений
  .select__menu-list {
    @include custom-scrollbar;
  }

  // Элемент с названием выбранного элемента при мультивыборе
  .select__multi-value__label {
    padding: 1px 1px 1px 3px;
  }

  // Отдельный пункт меню
  .select__option {
    border: none;
    color: $color-light-primaryToWhite;
    @include theme-aware("color", "color-primaryToWhite");
    transition: 0.2s;

    &--is-focused,
    &--is-selected,
    &:active,
    &:hover {
      background-color: $color-light-grey30ToGrey120;
      @include theme-aware("background-color", "color-grey30ToGrey120");
      color: $color-light-primaryToWhite;
      @include theme-aware("color", "color-primaryToWhite");
    }
  }
}

@import "types";
