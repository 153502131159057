@import "../abstracts/vars";
@import "../abstracts/mixins";

// Группа классов для установки размера заголовков
.title-xxl {
  font: 32px $bold;
  line-height: 40px;
}

.title-xl {
  font: 30px $bold;
  line-height: 38px;
}

.title-lg {
  font: 28px $bold;
  line-height: 36px;
}

.title-md {
  font: 26px $bold;
  line-height: 34px;
}

.title-sm {
  font: 24px $bold;
  line-height: 30px;
}

// Группа классов для установки размера текста
.text-xxl {
  @include text-xxl;
}

.text-xl {
  @include text-xl;
}

.text-lg {
  @include text-lg;
}

.text-md {
  @include text-md;
}

.text-sm {
  @include text-sm;
}

// Группа классов для установки семейства шрифта у текста
.text-bold {
  font-family: $bold;
}

.text-medium {
  font-family: $medium;
}

.text-regular {
  font-family: $regular;
}

// Классы для установки отступа любому элементу
.text-vgap16 {
  @include vgap16;
}

.text-vgap24 {
  @include vgap24;
}

/* Класс для выравнивания любого элемента относительно родителя */
.text-left {
  @include text-left;
}

.text-right {
  @include text-right;
}

.text-center {
  @include text-center;
}
