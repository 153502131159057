@import "src/assets/scss/abstracts/vars";
@import "src/assets/scss/abstracts/mixins";

// Базовые стили для полей ввода с указанными типами
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="search"],
input[type="datetime-local"],
textarea {
  -webkit-appearance: none;
  transition: outline 0.2s;

  &:hover,
  &:focus {
    outline: 2px solid $color-accent;
    @include theme-aware("outline-color", "color-accent");
  }
}

// Фикс для наслоения системной браузерной и кастомной иконок
input[type="password"] {
  padding-right: 50px;
}

// Контейнер на случай если внутри поля ввода нужно отобразить кнопку
.input-wrapper {
  position: relative;

  // Позиционирование любого элемента внутри родителя относительно нативного input
  .input + * {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}

// Базовый класс для поля ввода
.input {
  font-family: $regular;
  border: none;
  outline: 1px solid $color-light-grey30ToGrey120;
  border-radius: 8px;
  transition: outline-color 0.2s;

  // Если поле ввода не редактируемое
  &[disabled] {
    background-color: $color-light-grey05ToGrey140;
    color: $color-light-grey60ToGrey100;
    outline: none;
    cursor: not-allowed;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}

// Блок-обертка для отображения перечня ошибок валидации
.input-errors {
  margin-top: 7px;

  // Отдельная ошибка валидации
  &__error {
    @include text-sm;
    color: $color-light-red100ToRed60;
  }
}

@import "placeholder";
@import "types";
