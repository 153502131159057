@font-face {
  font-family: "Roboto-Bold";
  font-display: auto;
  src: url("../../fonts/roboto-bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Medium";
  font-display: auto;
  src: url("../../fonts/roboto-medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Regular";
  font-display: auto;
  src: url("../../fonts/roboto-regular.woff") format("woff");
}
