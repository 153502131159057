@import "src/assets/scss/abstracts/mixins";

::-webkit-input-placeholder {
  color: $color-light-grey60ToGrey100;
}

::-moz-placeholder {
  color: $color-light-grey60ToGrey100;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color-light-grey60ToGrey100;
}
