@import "src/assets/scss/abstracts/mixins";

.alert {
  // Уведомление об ошибке
  &--error {
    background-color: $color-light-red10ToRed140;
    color: $color-light-red100ToRed60;
  }

  // Уведомление с предупреждением
  &--warn {
    background-color: $color-light-orange10ToOrange140;
    color: $color-light-orange60ToOrange100;
  }

  // Уведомление об успешном выполнении действия
  &--success {
    background-color: $color-light-green10ToGreen140;
    color: $color-light-green120ToGreen30;
  }
}
