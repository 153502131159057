// Лоудер пока выполняется запрос на получение данных
.spinner {
  margin: auto;
  max-width: 24px;
  max-height: 24px;

  // Вращающаяся иконка
  svg {
    animation: rotating 1s linear infinite;
  }
}

// Анимация для иконки
@keyframes rotating {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
